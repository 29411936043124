<template>
   <header>
      <div class="topbar d-flex align-items-center">
         <nav class="navbar navbar-expand">
            <div class="topbar-logo-header">
               <div class="">
                  <img src="@/assets/images/logo-icon.png" class="logo-icon" alt="logo icon">
               </div>
               <div class="">
                  <h4 class="logo-text"><span class="d-block">Carolina</span><small class="d-block font-10 weight-500">Gerencial</small></h4>
               </div>
            </div>
            <div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
            <div class="search-bar flex-grow-1"></div>
				<div class="top-menu ms-auto">
					<ul class="navbar-nav align-items-center">
						<li class="nav-item" v-if="urlAjuda">
							<a class="nav-link" :href="urlAjuda" target="_blank" role="button"><i class='bx bx-help-circle'></i></a>
						</li>
						<li class="nav-item dropdown dropdown-large d-flex">
							<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<span class="alert-count" v-if="notificacoes.length > 0">{{ notificacoes.length }}</span>
								<i class='bx bx-bell'></i>
							</a>
							<div class="dropdown-menu dropdown-menu-end">
								<a href="javascript:;">
									<div class="msg-header"><p class="msg-header-title">Notificações</p></div>
								</a>
								<div class="header-notifications-list">
									<notificacao v-for="(notificacao, index) in notificacoes" :key="index" :notificacao="notificacao" :index="index" />

									<a class="dropdown-item cursor-auto" href="javascript:;" v-if="notificacoes.length == 0">
										<div class="d-flex align-items-center">
											<div class="flex-grow-1 msg-info text-center my-5">
												<i class="fal fa-bell-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
												<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma notificação encontrada</h4>
											</div>
										</div>
									</a>
								</div>
							</div>
						</li>
					</ul>
				</div>
            <div class="user-box dropdown">
               <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="dadosUsuario.usuarioAvatar == null ? '' : dadosUsuario.usuarioAvatar" @error="imageError" class="user-img rounded-1 bg-white" alt="Avatar">
                  <div class="user-info ps-3">
                     <p class="user-name mb-0">{{ String(dadosUsuario.usuarioNome).length >= 15 ? dadosUsuario.usuarioNome.substring(0, 15) + '...' : dadosUsuario.usuarioNome }}</p>
                     <p class="designattion mb-0">{{ String(dadosUsuario.usuarioEmail).length >= 15 ? dadosUsuario.usuarioEmail.substring(0, 15) + '...' : dadosUsuario.usuarioEmail }}</p>
                  </div>
               </a>
               <ul class="dropdown-menu dropdown-menu-end">
						<li>
                     <a class="dropdown-item" href="javascript:;" @click="abrirPerfil"><i class="far fa-user font-12 me-2"></i><span> Perfil</span></a>
                  </li>
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="deslogar"><i class="far fa-sign-out-alt font-12 me-2"></i><span> Sair</span></a>
                  </li>
               </ul>
            </div>
         </nav>
      </div>

      <div class="nav-container">
			<div class="mobile-topbar-header">
				<div>
					<img src="@/assets/images/logo-icon.png" class="logo-icon" alt="logo icon">
				</div>
				<div>
					<h4 class="logo-text">Carolina</h4>
				</div>
				<div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i></div>
			</div>
			<nav class="topbar-nav">
				<ul class="metismenu" id="menu">
					<li>
						<router-link to="/paginaInicial/0"><i class='bx bx-home-circle color-theme'></i><span>Página inicial</span></router-link>
					</li>
					<li>
						<router-link :to="'/contas/'+ tabs.length" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar contas')">
							<i class='bx bx-sitemap color-theme'></i><span>Contas</span>
						</router-link>
						<a href="javascript:;" class="disabled" v-else><i class='bx bx-lock-alt color-theme'></i><span>Contas</span></a>
					</li>
					<li>
						<router-link :to="'/closers/'+ tabs.length" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar closers')">
							<i class='bx bx-group color-theme'></i><span>Closers</span>
						</router-link>
						<a href="javascript:;" class="disabled" v-else><i class='bx bx-lock-alt color-theme'></i><span>Closers</span></a>
					</li>
					<li>
						<router-link :to="'/usuarios/'+ tabs.length" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar usuários')">
							<i class='bx bx-user color-theme'></i><span>Usuários</span>
						</router-link>
						<a href="javascript:;" class="disabled" v-else><i class='bx bx-lock-alt color-theme'></i><span>Usuários</span></a>
					</li>
					<li>
						<router-link :to="'/blog/'+ tabs.length" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar blog')">
							<i class='bx bx-conversation color-theme'></i><span>Blog</span>
						</router-link>
						<a href="javascript:;" class="disabled" v-else><i class='bx bx-lock-alt color-theme'></i><span>Blog</span></a>
					</li>
					<li>
						<router-link :to="'/trabalheConosco/'+ tabs.length" v-if="dadosUsuario.usuarioPermissoes.includes('Visualizar trabalhe conosco')">
							<i class='bx bx-briefcase color-theme'></i><span>Trabalhe conosco</span>
						</router-link>
						<a href="javascript:;" class="disabled" v-else><i class='bx bx-lock-alt color-theme'></i><span>Trabalhe conosco</span></a>
					</li>
				</ul>
			</nav>
		</div>
   </header>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import notificacao from '@/components/Notificacao.vue'

export default {
	name: 'MenuNavBar',
	data: function () {
		return {
			notificacoes: []
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         isLogado: state => state.isLogado,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs,
			urlAjuda: state => state.urlAjuda
      })
   },
	watch: {
		isLogado : function (value) {
			if (value) {
				this.getNotificacoes()
			}
		}
	},
	components: {
		notificacao
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      deslogar : function () {
			this.$store.dispatch('deslogar')
      },
		getNotificacoes : function () {
			this.notificacoes = []

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getNotificacoes'

			}).then(response => {
				this.notificacoes = response.data
			});
		},
		abrirPerfil : function () {
			this.$emit('abrirEditarPerfil')
		}
	},
   mounted() {
      Vue.nextTick(function () {
			$(function () {
				for (var i = window.location, o = $(".metismenu li a").filter(function () {
					return this.href == i;
				}).addClass("").parent().addClass("");;) {
					if (!o.is("li")) break;
					o = o.parent("").addClass("").parent("").addClass("");
				}
				
				$('#menu').metisMenu();
			});

         $(".mobile-toggle-menu").on("click", function () {
            $(".wrapper").addClass("toggled");
         });

         $(".toggle-icon").click(function () {
            if ($(".wrapper").hasClass("toggled")) {
               // unpin sidebar when hovered
               $(".wrapper").removeClass("toggled");
               $(".sidebar-wrapper").unbind("hover");
            } else {
               $(".wrapper").addClass("toggled");
               $(".sidebar-wrapper").hover(function () {
                  $(".wrapper").addClass("sidebar-hovered");
               }, function () {
                  $(".wrapper").removeClass("sidebar-hovered");
               })
            }
         });
		}.bind(this));
   }
}

</script>