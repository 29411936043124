<template>
	<div class="wrapper">
		<div class="section-authentication-signin d-flex align-items-center justify-content-center my-0">
			<div class="container-fluid">
				<div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
					<div class="col mx-auto">
						<div class="card">
							<div class="card-body">
								<div class="border p-4 rounded">
									<div class="text-center">
                              <div class="mb-4 text-center">
                                 <img src="@/assets/images/logo-img.png" class="logo-login" width="180" alt="" />
                              </div>
                              <h6 class="mb-3 font-18" v-if="!esqueceuSenha">Faça login para continuar</h6>
                              <h6 class="mb-3 font-18" v-else>Esqueci minha senha</h6>
                           </div>
									<div class="form-body">
										<form class="row g-3">
											<div class="col-12">
                                    <label class="mb-1"><i class="far fa-envelope me-2 font-12"></i>Email</label>
                                    <input type="text" class="form-control font-14" placeholder="Digite seu email" v-model="usuario" @keyup.enter="verificarLogin">
                                 </div>
                                 <div class="col-12" v-if="!esqueceuSenha">
                                    <label class="mb-1"><i class="far fa-lock me-2 font-12"></i>Senha</label>
                                    <input type="password" class="form-control font-14" placeholder="Digite sua senha" v-model="senha" @keyup.enter="verificarLogin">
                                 </div>
											<div class="col-sm-7" v-if="!esqueceuSenha">
												<div class="form-check form-switch w-max-content mx-auto mx-sm-0">
                                       <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="manterConectado">
                                       <label class="form-check-label" for="flexSwitchCheckChecked">Mantenha-me conectado</label>
                                    </div>
											</div>
											<div class="text-center" :class="esqueceuSenha ? 'col-12' : 'col-sm-5 text-sm-end'">
												<a href="javascript:;" class="color-theme" v-if="!esqueceuSenha" @click="esqueceuSenha = true;">Esqueceu a senha?</a>
												<a href="javascript:;" class="color-theme" v-else @click="esqueceuSenha = false;">Voltar para login</a>
											</div>
											<div class="col-12">
												<div class="d-grid" v-if="!esqueceuSenha">
													<button type="button" class="btn btn-primary font-14 d-flex justify-content-center align-items-center" @click="verificarLogin">
                                          <i class="far fa-sign-in-alt font-14 d-block me-2"></i>Entrar
                                       </button>
												</div>
												<div class="d-grid" v-else>
													<button type="button" class="btn btn-primary font-14 d-flex justify-content-center align-items-center" @click="recuperarSenha">
                                          <i class="far fa-share font-14 d-block me-2"></i>Enviar
                                       </button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Login',
   data: function() {
		return {
			usuario: '',
         senha: '',
         manterConectado: false,
         esqueceuSenha: false
		}
	},
	computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
		verificarLogin : function () {
			if (this.esqueceuSenha) {
				this.recuperarSenha()

			} else if (String(this.usuario).trim().length > 0 && String(this.senha).trim().length > 0) {
            this.$store.dispatch('verificarCredenciais', {'usuario': this.usuario, 'senha': this.senha, 'manterConectado': this.manterConectado});
         }
		},
		recuperarSenha : function () {
			if (String(this.usuario).trim().length == 0) {
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'loginAdmin/recuperacao',
				headers: {'Content-Type': 'application/json'},
				data: {'email': this.usuario}

         }).then(() => {
				this.esqueceuSenha = false;
				
				Swal.fire({
					icon: 'success',
					title: 'Email de recuperação enviado!',
					text: 'Verifique sua caixa de email.',
					showConfirmButton: false,
					showCancelButton: true,
					cancelButtonText: `Fechar`
				})
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		}
	}
}

</script>